
.ticker ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  white-space: nowrap;
  animation: ticker 35s linear infinite;
}

.ticker {
  position: fixed;
  bottom: 0;
  background-color: black;
  color: white !important;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 3vh;
  overflow: hidden; /* hide the overflowing content */
}

.ticker li {
  display: inline-block;
  padding-right: 20px;
}
/* change those for the ticker to rotate better */
@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-110%);
  }
}

