/* Pre-Defined Variables */
:root{
  --page-background: #F0F3F5;
  --white: #FFFFFF;
  --black: #000000;
  --purple: #9747FF;
  --blue: #4784FF;
  --cyan: #65BEFF;
  --shades: rgba(0, 0, 0, 0.25);

  --title: 2.25rem;
  --subtitle: 1.563rem;
  --body: 0.938rem;

}
h1{
  font-size: var(--title)!important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
}
h2{
  font-size: var(--subtitle)!important;
  font-weight: 400 !important;
  font-family: 'Roboto', sans-serif !important;
}
h3{
  font-size: 1.25rem !important;
  font-weight: 300 !important;
  font-family: 'Roboto', sans-serif !important;
}
p{
  font-size: var(--body) !important;
  font-weight: 300 !important;
  font-family: 'Roboto', sans-serif !important;
}

.body{
  background-color: var(--page-background);
  display: flex;
  flex-direction: column;
}
.main_container{
  position: relative;
  margin: 0% 0% 0% 20%;
}
.submain_container{
  margin: 2% 5% 4% 5%;
}
.graph_container{
  display: flex;
  flex-direction: column;
}
