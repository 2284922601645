.page_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.mylogo{
  width: 13%;
  height: 23%;
}
.mylogo_image{
  width: 100%;
  height: 100%;
}

/* ---------------------------------- */
/* ---------------------------------- */


/* Myitem */
.myitem{
  background-color: white;
  border-radius: 8px;
  height: 17%;
  padding: 1% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
}
.myitem_container{
  display: flex;
  width: 50%;
  align-items: center;
}
.myitem_h3{
  width: 60%;
}

/* My actuators */

.myactuators{
  background-color: white;
  border-radius: 8px;
  height: 15%;
  padding: 1% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
}
.myactuators_container{
  margin: 0 2%;
  gap: 5%;
  display: flex;
  width: 50%;
  align-items: center;
}
.myactuators>h3{
  width: 60%;
}
/* My weather box */

.my_weather_box{
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  justify-content: space-evenly;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.weather_row1{
  /* background-color: yellow; */
  margin: 0 2%;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  height: 35%;
}
.weather_row2{
  margin: 0 2%;
  display: flex;
  height: 25%; 
  justify-content: space-around;
}
.weather_small_box{
  background-color: var(--page-background);
  border-radius: 8px;
  width: 43%;
  display: flex;
  flex-direction: column;
  padding:  1% 2%;
  justify-content: space-evenly;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
.weather_logo{
  width: 30%;
  height: 100%;
}