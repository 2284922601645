/* Login Page */

.h1{
  /* color: var(--white) !important; */
}

.body{
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--purple); */
}
.image_container{
  width: 80vw;
  height: 100vh;
}
.image{
  width: 100%;
  height: 100%;
  filter: blur(2px) brightness(0.6);
}
.main_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 25%;
  /* background-color: var(--purple); */
  gap: 4%;
}
.submain_container{
  /* background-color: cyan; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}
.textfield_container{
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 15px;
 }
.field{
  width: 100% ;
}
.links{
  line-height: 25px;
  text-align: center;
}
.button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo{
  width: 80%;
  padding-bottom: 5%;
}