.body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_container{
  width: 80vw;
  height: 100vh;
}
.image{
  width: 100%;
  height: 100%;
  filter: blur(2px) brightness(0.6);
}
.main_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 25%;
  gap: 4%;
}
.submain_container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}
.textfield_container{
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 15px;
 }
.field{
  width: 100% ;
}
.links{
  line-height: 30px;
}
.logo{
  width: 80%;
  padding-bottom: 5%;
}