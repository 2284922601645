.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 100vh;
  background-color: var(--purple);
}
.logo_container {
  padding: 23% 0% 30% 0;
  width: 80%;
}
.logo {
  width: 100%;
  height: 100%;
}
.buttons_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttons{
  border-radius: 4px;
  display: flex;
  margin: 5% 0% !important;
  padding: 0 10px;
  gap: 50px;
  background-color: var(--shades);
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 5vh;
  text-decoration: none !important;
  cursor: pointer;
}
.buttons:hover{
  font-weight: 500;
}
.logos{
  width: 3vh;
  height: 3vh;
}
.buttons_logo{
  width: 100%;
  height: 100%;
  margin-right: 10px;
  color: white;
  text-decoration: none !important;
}

.links{
  color: rgb(255, 255, 255) !important;
  size: inherit !important;
}
.fields_container{
  display: flex;
  flex-direction: column;
  gap: 5%;
  width: 80%;
  height: 50%;
}
.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--page-background);
  border-radius: 8px;
  width: 30%;
  height: 70%;
  overflow-y: auto;
}
.modal_buttons{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ticker_modal_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--page-background);
  border-radius: 8px;
  width: 30%;
  height: 50%;
  overflow-y: auto;
}
.ticker_modal_buttons{
  display: flex;
  justify-content: center;
  align-items: center;
}