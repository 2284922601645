.actuator_settings{
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margiun */
}
.settings_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 4% 0 0 0;
}