.container1{
  margin: 5% 0.1vw 0 0.1vw;
  display: flex;
  height: fit-content;
  flex-direction: column;
}
.cards_container{
  margin: 2% 0 0 0;
  display: flex;
  justify-content: space-between;
}
.mybox{
  width: 15vw;
  border-radius: 8px;
  color: white;
  height: 8vw;
  background-color: var(--cyan);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.mybox h3{
  font-size: 1.5vw !important;
}
.mybox:hover{
  cursor: pointer;
  filter: none;
}
.container2{
  display: flex;
  margin : 5% 0 0 0;
  height: fit-content;
  justify-content: space-between;
}
.actuators{
  object-fit: fill;
  width: 47.4%;
  display: flex;
}
.plant{
  width: 47%;
  display: flex;
  object-fit: fill;
}
.container3{
  display: flex;
  flex-direction: column;
  margin : 5% 0 0 0;
}

.weather_cards_container{
  margin: 2% 0 0 0;
  display: flex;
  height: 30vh;
  justify-content: space-between;
}