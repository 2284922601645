.plant_body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  /* object-fit: fill; */
}
.container_title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2%;
  height: 7%;
}
.container_logo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4%;
}
.logo{
  height: 100%;
  width: 100%;
}

.plant_details_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  gap: 3%;
  margin: 4% 0 0 0;
  background-color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.first_row{
  display: flex;
  justify-content: space-between;
  width: 96%;
  height:35%;
}

.plant_image_container{
  width: 23%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plant_image{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.plant_species{
  background-color: var(--page-background);
  display: flex;
  flex-direction: column;
  padding: 2%;
  justify-content: space-between;
  width: 71%;
  border-radius: 8px;
}
.second_row{
  display: flex;
  justify-content: space-between;
  width: 96%;
  height: 25%;
}
.ai_readings{
  /* background-color: white; */
  background-color: var(--page-background);
  display: flex;
  flex-direction: column;
  padding: 1% 2%;
  justify-content: space-between;
  width: 45%;
  border-radius: 8px;
}

/* Row plant styles: */
.row_plant_body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 33vh !important;
  margin-bottom: 7%;
}
.row_plant_details_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  gap: 3%;
  margin: 2% 0 0 0;
  background-color: var(--white);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.row_first_row{
  display: flex;
  justify-content: space-between;
  width: 97%;
  height:55%;
}

.row_plant_image_container{
  width: 24.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row_plant_image{
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.row_plant_species{
  display: flex;
  flex-direction: column;
  padding: 1%;
  justify-content: space-between;
  width: 72.8%;
  border-radius: 8px;
  background-color: var(--page-background);
}
.row_second_row{
  display: flex;
  justify-content: space-between;
  width: 97%;
  height: 30%;
}
.row_ai_readings{
  background-color: var(--page-background);
  display: flex;
  flex-direction: column;
  padding: 1%;
  justify-content: space-between;
  width: 22.5%;
  border-radius: 8px;
}
.row_ai_sentence{
  background-color: var(--page-background);
  display: flex;
  flex-direction: column;
  padding: 1%;
  justify-content: space-between;
  width: 72.8%;
  border-radius: 8px;
}