.body{
  display: flex;
  align-items: center;
  justify-content: center;
}
.image_container{
  width: 80vw;
  height: 100vh;
}
.image{
  width: 100%;
  height: 100%;
  filter: blur(2px) brightness(0.6);
}
.main_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 25%;
  gap: 4%;
}
.submain_container{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
}
.name_fields{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.textfield_container{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
 }
 .field{
  width: 47% ;
 }
 .button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo{
  width: 80%;
  padding-bottom: 5%;
}